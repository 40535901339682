import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import FormGroup from "@material-ui/core/FormGroup"
import api from "../api"
import { TextField, Checkbox, FormControlLabel } from "@material-ui/core"
import Container from "./container"
import theme from "../theme"
import Valid from "./validator"

const valid = new Valid()

// TODO Implement submission and fully working validation

export default function BookingAgentForm({ query }) {
  // Get today's date
  const getDate = () => {
    let newDate = new Date()
    let date = newDate.getDate()
    if (date < 10) {
      date = "0" + date
    }
    let month = newDate.getMonth() + 1
    if (month < 10) {
      month = "0" + month
    }
    let year = newDate.getFullYear()

    return `${year}-${month}-${date}`
  }

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phone: "",
    from: query.from ? query.from : "",
    to: query.to ? query.to : "",
    depDate: query.depDate ? query.depDate : getDate(),
    returnDate: query.returnDate ? query.returnDate : getDate(),
    minDepDate: getDate(),
    minReturnDate: getDate(),
    passengers: "1",
    crypto: "Bitcoin",
    message: "",
    checkbox: "notchecked",
    checkbox2: "notchecked",
  })

  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    phone: false,
    from: false,
    to: false,
    depDate: false,
    checkbox: false,
    checkbox2: false,
  })

  const [isvalid, setValid] = useState(false)
  const [submit, setSubmit] = useState(false)

  useEffect(() => {
    const result = valid.formValid(formValues, formErrors)
    setFormErrors(result.formErrors)
    setValid(result.valid)
  }, [formErrors, formValues])

  const handleSubmit = async (e) => {
    e.preventDefault()

    // Check if form is valid
    const result = valid.formValid(formValues, formErrors)
    let errors = result.formErrors

    // Send email if form is valid
    if (result.valid) {
      setSubmit(true)
      console.log("sending")
      try {
        await api.post(
          "https://accounts.travelbybit.com/customer-enquiries/booking-request",
          {
            timestamp: Date.now(),
            details: {
              name: formValues.name,
              email: formValues.email,
              phone_number: formValues.phone,
              destination_from: formValues.from,
              destination_to: formValues.to,
              departure_date: formValues.depDate,
              return_date: formValues.returnDate,
              passengers: formValues.passengers,
              cryptocurrency: formValues.crypto,
              comments: formValues.message,
            },
          }
        )
        setSubmit(false)
      } catch (e) {
        console.error(e)
        setSubmit(false)
      }
    } else {
      setFormErrors(errors)
    }
  }

  // When the user enters something into an input
  const handleChange = (e) => {
    let { name, value } = e.target

    // For checkboxes
    if (value === "notchecked") {
      value = "checked"
    } else if (value === "checked") {
      value = "notchecked"
    }

    let values = formValues

    // Minimum return date
    if (name === "depDate") {
      values["minReturnDate"] = value
    }

    // Update values in state
    values[name] = value
    setFormErrors(errors)
  }

  return (
    <StyledContainer>
      <form onSubmit={handleSubmit} className="form">
        <div className="form-field title">
          <TextField
            name="title"
            select
            label="Title"
            onChange={handleChange}
            required
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          >
            <option value="mr">Mr</option>
            <option value="ms">Ms</option>
            <option value="misss">Miss</option>
            <option value="mrs">Mrs</option>
          </TextField>
        </div>

        <div className="form-field name">
          <TextField
            label="First Name"
            name="firstName"
            placeholder="First Name"
            onChange={handleChange}
            required
            helperText="as it appears on the passport"
            variant="outlined"
          />

          <TextField
            label="Last Name"
            name="lastName"
            placeholder="Last Name"
            onChange={handleChange}
            required
            helperText="as it appears on the passport"
            variant="outlined"
          />
        </div>

        <div className="form-field email">
          <TextField
            label="Email"
            type="email"
            name="email"
            placeholder="Email"
            onChange={handleChange}
            required
            variant="outlined"
          />
        </div>

        <div className="form-field phone">
          <TextField
            label="Phone"
            type="tel"
            name="phone"
            placeholder="Phone Number"
            onChange={handleChange}
            required
            variant="outlined"
          />
        </div>

        <div className="form-field from">
          <TextField
            label="Travelling From"
            type="text"
            name="from"
            placeholder="City Name"
            onChange={handleChange}
            required
            variant="outlined"
          />
        </div>

        <div className="form-field to">
          <TextField
            label="Travelling To"
            type="text"
            name="to"
            placeholder="City Name"
            onChange={handleChange}
            required
            variant="outlined"
          />
        </div>

        <div className="form-field dep-date">
          <TextField
            label="Departure Date"
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            name="depDate"
            onChange={handleChange}
            required
            variant="outlined"
          />
        </div>

        <div className="form-field ret-date">
          <TextField
            label="Return Date (if applicable)"
            InputLabelProps={{
              shrink: true,
            }}
            type="date"
            name="return"
            onChange={handleChange}
            required
            variant="outlined"
          />
        </div>

        <div className="form-field passengers">
          <TextField
            name="passengers"
            select
            label="Passengers"
            onChange={handleChange}
            required
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6+">6+</option>
          </TextField>
        </div>

        <div className="form-field info">
          <TextField
            id="outlined-textarea"
            label="Anything else we should know"
            name="message"
            onChange={handleChange}
            multiline
            variant="outlined"
          />
        </div>

        <div className="pre-submit">
          <div className="check-field">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleChange}
                  required
                  name="checkbox"
                  value={formValues.checkbox}
                />
              }
              label={
                <p>
                  By proceeding, I agree to TravelbyBit’s{" "}
                  <Link to="/booking-terms-and-conditions">
                    Booking Terms and Conditions
                  </Link>{" "}
                  and <Link to="/privacy-policy">Privacy Policy</Link>, and
                  acknowledge that I have read and understood TravelbyBit's{" "}
                  <Link to="/booking-payents-guidelines">
                    Booking and Payments Guidelines
                  </Link>
                  .
                </p>
              }
            />
          </div>
          <div className="please-read">
            <h3>PLEASE READ BEFORE SUBMITTING</h3>
            <ol>
              <li>
                Please ensure that the website URL is{" "}
                <Link to="/book-with-a-travel-agent">
                  https://www.travelbybit.com/book-with-a-travel-agent.
                </Link>
              </li>
              <li>
                You will ONLY be asked to make a payment after you receive a
                personal booking reference from a TravelbyBit travel agent.
              </li>
              <li>
                ONLY send your coins to a cryptocurrency wallet address
                generated from the TravelbyBit secure payment portal.
              </li>
              <li>
                If you notice anything suspicious, or have any questions
                regarding the payment process, please{" "}
                <Link to="/contact-us">contact us.</Link>
              </li>
            </ol>
          </div>
          <div className="check-field">
            <FormControlLabel
              control={
                <Checkbox
                  onChange={handleChange}
                  required
                  name="checkbox2"
                  value="checkedA"
                />
              }
              label="I have read and understood the above notice."
            />
          </div>
        </div>
        <StyledButton type="submit" purple large disabled={submit || !isvalid}>
          Submit
        </StyledButton>
      </form>
    </StyledContainer>
  )
}

const StyledContainer = styled(Container)`
  background: #fff;
  border-radius: 4px;
  margin-top: 2em;
  padding: 2em;

  .form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 2rem;
    grid-column-gap: 1rem;
    grid-template-areas: "title name" "email phone" "from to" "depart return" "passengers crypto" "info info" "presubmit presubmit" "submit submit";

    .form-field {
      display: flex;
      flex-direction: column;
      text-align: center;

      label {
        margin-bottom: 0.5rem;
      }

      label,
      input,
      h3 {
        color: ${theme.fontColourLight};
      }
    }

    .title {
      grid-area: title;
    }

    .name {
      grid-area: name;
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-template-areas: "label label" "firstname lastname";
      grid-column-gap: 0.5rem;

      label {
        grid-area: label;
      }

      input[type="firstname"] {
        grid-area: firstname;
      }

      input[type="lastname"] {
        grid-area: lastname;
      }
    }

    .email {
      grid-area: email;
    }

    .phone {
      grid-area: phone;
    }

    .from {
      grid-area: from;
    }

    .to {
      grid-area: to;
    }

    .dep-date {
      grid-area: depart;
    }

    .ret-date {
      grid-area: return;
    }

    .passengers {
      grid-area: passengers;
    }

    .crypto {
      grid-area: crypto;
    }

    .info {
      grid-area: info;
      display: flex;
      justify-content: center;
    }

    .pre-submit {
      grid-area: presubmit;
      color: ${theme.fontColourLight};

      .check-field {
        display: flex;

        input[type="checkbox"] {
          margin-right: 1rem;
        }
      }

      .please-read {
        border: 1px solid red;
        border-radius: 4px;
        padding: 1.5rem;
        margin: 1.5rem 0;

        h3 {
          text-align: center;
          margin-bottom: 1rem;
        }

        ol {
          list-style-position: inside;

          li:nth-child(-n + 3) {
            margin-bottom: 0.5rem;
          }
        }
      }

      a {
        color: ${theme.orange};
        text-decoration: none;
      }
    }

    button {
      grid-area: submit;
      width: 12em;
      margin: 2em auto;
    }
  }

  /* Mobile styles */
  @media only screen and (max-width: 989px) {
    .form {
      grid-template-columns: 1fr;
      grid-template-areas: "title" "name" "email" "phone" "from" "to" "depart" "return" "passengers" "crypto" "info" "presubmit" "submit";

      .name {
        input {
          width: 100%;
        }
      }
    }
  }
`

const StyledButton = styled.button`
  background: #f66415;
  border: none;
  color: #fff;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-family: ProximaNova;
  font-size: 16px;
  padding: 10px 20px;
  line-height: 1.5;

  &:disabled {
    background: #757575;
  }

  &:hover {
    cursor: pointer;
  }
`

import React from "react"
import styled from "styled-components"
import queryString from "query-string"
import BookingAgentForm from "../components/bookingAgentForm"
import theme from "../theme"
import SEO from "../components/seo"

export default function BookWithTravelAgent({ location }) {
  const query = queryString.parse(location.search)

  return (
    <>
      <SEO title="Book With a Travel Agent" />
      <Page>
        <div className="container">
          <h1 className="heading">Prefer to book with a travel agent?</h1>
          <p className="subtext">
            Simply fill in the form below and one of our experienced Travel
            Agents will be in touch with you as soon as possible.{" "}
          </p>
          <BookingAgentForm query={query} />
        </div>
      </Page>
    </>
  )
}

const Page = styled.div`
  padding: 3em 0;
  min-height: calc(100vh - 65px);
  color: #333;
  background: #f5f5f5;
  margin-top: -2em;

  @media only screen and (max-width: 1169px) {
    /* padding: 130px 1rem 0 1rem; */
  }

  @media only screen and (max-width: 989px) {
    padding-bottom: 5em;
  }

  .container {
    max-width: 1170px;
    margin: 0 auto;
  }

  .heading {
    color: ${theme.fontColourDarkBlue};
    text-align: center;
    font-weight: 400;
    margin-bottom: 1rem;
  }

  .subtext {
    color: ${theme.fontColourLight};
    text-align: center;
    font-size: ${theme.fontSizeHeadingMed};
  }
`

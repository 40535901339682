export default class Validator {
  // Regex for Email
  emailRegex = value => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(value.toLowerCase())
  }

  // Regex for letters and space
  letterRegex = value => {
    var re = /^[a-zA-Z\s]*$/
    return re.test(value)
  }

  // Regex for Phone number
  phoneRegex = value => {
    var re = /^[+\d]?(?:[\d-.\s()]*)$/
    return re.test(value)
  }

  // Validate form before submit
  formValid = (formValues, formErrors) => {
    let valid = true
    const checklist = formErrors

    // Pass from through conditions
    Object.keys(checklist).map(
      key => (formErrors = this.conditions(key, formValues[key], formErrors))
    )

    // Check if there's errors
    Object.values(formErrors).forEach(val => {
      if (val) {
        valid = false
      }
    })

    return { valid, formErrors }
  }

  // Form conditions that must be met
  conditions = (name, value, formErrors) => {
    switch (name) {
      case "type":
        formErrors.type = value.length === 0
        break
      case "firstName":
        formErrors.firstName =
          !this.letterRegex(value) || value === null || value.length === 0
        break
      case "lastName":
        formErrors.lastName =
          !this.letterRegex(value) || value === null || value.length === 0
        break
      case "email":
        formErrors.email = !this.emailRegex(value)
        break
      case "phone":
        formErrors.phone = !this.phoneRegex(value) || value.length === 0
        break
      case "from":
        formErrors.from = !this.letterRegex(value) || value.length === 0
        break
      case "to":
        formErrors.to = !this.letterRegex(value) || value.length === 0
        break
      case "depDate":
        formErrors.depDate = value.length === 0
        break
      case "message":
        formErrors.message = value.length === 0
        break
      case "checkbox":
        formErrors.checkbox = value === "notchecked"
        break
      case "checkbox2":
        formErrors.checkbox2 = value === "notchecked"
        break
      case "password":
        formErrors.password = value.length < 8
        break
      case "curpw":
        formErrors.curpw = value.length === 0
        break
      default:
    }
    return formErrors
  }
}
